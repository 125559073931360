import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import BubbleButton from './bubblebutton';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import emailjs from 'emailjs-com';
import { Link } from 'react-scroll';
import {FaAddressCard,FaMailBulk,FaPhoneAlt} from "react-icons/fa";

//import useActiveSection from './activesection.js';
function App() {

  //const activeSection = useActiveSection();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [isPaused,  setIsPaused] = useState(false);
  
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const form = useRef();
    const email2=useRef(null);
    const message2=useRef(null);
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === 'name') {
        setName(value);
      } else if (name === 'email') {
        setEmail(value);
      } else if (name === 'message') {
        setMessage(value);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Send email via backend or third-party service
      sendEmail(name, email, message);
  
      // Reset form fields
      setName('');
      setEmail('');
      setMessage('');
    };
    const sendEmail = (name, email, message) => {
     
      // Implement email sending logic here (e.g., using a backend API or third-party service)
      // You can make an HTTP request to your backend server to handle email sending
      emailjs.sendForm("service_mk36fqt","template_ulny3y6",form.current,"ufVbR8H11NywVKWV4");
    };
  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
      document.documentElement.setAttribute('data-theme', currentTheme);
      if (currentTheme === 'dark') {
        setDarkMode(true);
      }
    }
  }, []);

  
  
  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };
  const handleNavbarClose = () => {
    setNavbarOpen(false);
  };
 

  const handleThemeToggle = () => {
    const theme = darkMode ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
    setDarkMode(!darkMode);
  };

  const handleBubbleButtonClick = () => {
    setIsPaused(true);
  };

  const handlePopupClose = () => {
    setIsPaused(false);
  };

 // Add a new state to track whether the navbar is open or not
 const [isNavbarOverlayOpen, setIsNavbarOverlayOpen] = useState(false);

 useEffect(() => {
   // Update the state when the navbarOpen value changes
   setIsNavbarOverlayOpen(navbarOpen);
 }, [navbarOpen]);



  useEffect(() => {
    const handleScreenSize = () => {
      const screenWidth = window.innerWidth;

      // Check the screen size and run the desired function
      if (screenWidth >= 768) {
        setNavbarOpen(false);
      } 
    };

    // Call the handleScreenSize function initially
    handleScreenSize();

    // Add event listener to run the function on window resize
    window.addEventListener('resize', handleScreenSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleScreenSize);
    };
  }, []);

  const [activeSection, setActiveSection] = useState('');
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (window.pageYOffset >= sectionTop - 200 && window.pageYOffset < sectionTop + sectionHeight - 50) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [imagesLoaded, setImagesLoaded] = useState(false);

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };
  const [showMore, setShowMore] = useState(false);

  const toggleMoreInfo = (event) => {
    event.preventDefault();
    setShowMore(!showMore);
  };

  return (
<body>
    <div className={`App ${darkMode ? 'dark-theme' : ''}`}>
      
      <header className="App-header"></header>
      <div className="home-bg">
        
        <nav className="navbar">
          
          <div className="navbar-left">
            
          {!navbarOpen && (
          <img
            className="navbar-toggle"
            onClick={handleNavbarToggle}
            src={require("./images/navbar.png")}
            alt="RK consultancies pvt ltd"
          />
        )}
            <div className={`navbar-center ${navbarOpen ? 'navbar-center-open' : ''}`}>
          
            <Link
            to="Home"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            offset={-50}
            isDynamic={true}
            onClick={handleNavbarClose}
          ><img  src={require("./images/rk8.png")} className="brand-logo" alt="RK consultancies pvt ltd" /></Link>
         
            </div>
          </div>
 
          <ul className={`navbar-links ${navbarOpen ? 'open' : 'closed'}` }>
       
          <li>
          <Link
            to="Home"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            offset={-50}
            isDynamic={true}
            onClick={handleNavbarClose}
          >
            <span className={activeSection === 'Home' ? 'navbar-link active' : 'navbar-link'}>Home</span>
          </Link>
        </li>
        <li>
          <Link
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            offset={-50}
            isDynamic={true}
            onClick={handleNavbarClose}
          >
            <span className={activeSection === 'about' ? 'navbar-link active' : 'navbar-link'}>About</span>
          </Link>
        </li>
        <li>
          <Link
            to="services"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            offset={-50}
            isDynamic={true}
            onClick={handleNavbarClose}
          >
            <span className={activeSection === 'services' ? 'navbar-link active' : 'navbar-link'}>Services</span>
          </Link>
        </li>
        <li>
          <Link
            to="careers"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            offset={-50}
            onClick={handleNavbarClose}
            isDynamic={true}
          >
            <span className={activeSection === 'careers' ? 'navbar-link active' : 'navbar-link'}>Careers</span>
          </Link>
        </li>
        <li>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active"
            onClick={handleNavbarClose}
            offset={-50}
            isDynamic={true}
          >
            <span className={activeSection === 'contact' ? 'navbar-link active' : 'navbar-link'}>Contact</span>
          </Link>
        </li>
      </ul>
  
   <div className="toggle-btn" id="btn" onClick={handleThemeToggle}>
            <img src={darkMode ? require("./images/sun.png") : require("./images/moon.png")} id="btnIcon" alt="Toggle Icon" />
            
          </div>
        
          
        </nav>
        
        {isNavbarOverlayOpen && <div className="navbar-overlay" onClick={handleNavbarClose} />}
        
        <section id="Home">

        <div className="home-section-start"></div>
        <div className="header-text">
          <h1> <br /><span>RK</span> <br></br>CONSULTING</h1>
        </div>
     
     
        <div className="home-container">
        <a href="#services" className="grid-link">
            <div className="grid-item2">
              <p>What we have to offer</p>
            </div>
          </a>
          <a href="#about" className="grid-link">
            <div className="grid-item1">
              <p>Let's get acquainted</p>
            </div>
          </a>
          <a href="#careers" className="grid-link">
            <div className="grid-item3">
              <p>Join our team</p>
            </div>
          </a>
          <a href="#contact" className="grid-link">
            <div className="grid-item4">
              <p>Reach us out</p>
            </div>

          </a>
          </div>
          {/* <div className="spinner-container">
          <img src={require("./images/background.png")}></img>
            
             </div> */}
   
</section>
</div>


<section id="about">
        <div className="section-start"></div>
        <div className="sub-head">
        <h2>ABOUT US</h2>
        
        </div>
            <div class = "about-content">
               
             
                <p>Welcome to RK consultancies, your trusted partner in delivering exceptional consulting services. With a team of experienced professionals and a commitment to excellence, we strive to provide innovative solutions that drive success for our clients.

At RK consultancies, we understand the unique challenges faced by businesses today. Whether you're a startup looking for strategic guidance or an established organization seeking process optimization, our expertise spans across various industries and domains to cater to your specific needs.

What sets us apart is our client-centric approach.</p><p className='para-2'>

Our team of consultants brings a wealth of knowledge and experience to the table. We continuously invest in learning and development to ensure that our clients receive the most up-to-date and relevant advice. By combining our industry insights with data-driven analysis, we help you make informed decisions and seize new opportunities.


We invite you to explore our range of consulting services. we have the expertise and resources to drive your success.

Contact us today to schedule a consultation and take the first step towards unlocking your business's true potential.</p>
<div style={{ display: 'block',  paddingTop: 5}}>
   {!imagesLoaded && <div className="loading"></div>}
      <Carousel
        showArrows={true}
        showThumbs={false}
        autoPlay={true}
        showStatus={false}
        infiniteLoop={true}
        swipeable={true}
        className="carousel-container"
      >
        <div>
          <img
            className={`carousel-img ${imagesLoaded ? 'loaded' : ''}`}
            src={require('./images/carousel-bin.jpg')}
            alt="Carousel Image 1"
            onLoad={handleImagesLoad}
          />
        </div>
        <div>
          <img
            className={`carousel-img ${imagesLoaded ? 'loaded' : ''}`}
            src={require('./images/carousel-supply.jpg')}
            alt="Carousel Image 2"
            onLoad={handleImagesLoad}
          />
        </div>
        <div>
          <img
            className={`carousel-img ${imagesLoaded ? 'loaded' : ''}`}
            src={require('./images/carousel-tab.jpeg')}
            alt="Carousel Image 3"
            onLoad={handleImagesLoad}
          />
        </div>
        <div>
          <img
            className={`carousel-img ${imagesLoaded ? 'loaded' : ''}`}
            src={require('./images/carousel-vr.jpg')}
            alt="Carousel Image 4"
            onLoad={handleImagesLoad}
          />
        </div>
      </Carousel>
</div>

			<div class="text-center">
                <h4>OUR TEAM</h4>
        </div>
        <div className="button-container">
      <div className={`scroll-container`}>
        <BubbleButton
          title="Bhargavi"
          description="Managing Director"
          popupImage="m7.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
         <BubbleButton
          title="Sagar"
          description="Project Lead"
          popupImage="m1-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="Bipin"
          description="Chief Technical Officer"
          popupImage="m2-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="Vijay"
          description="Quality Assurance (QA) Lead"
          popupImage="m3-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="Rishin"
          popupImage="m5-min.png"
          description="Block Chain Lead"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="Lukin"
          popupImage="m4-min.png"
          description="Artificial Intelligence Lead"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
      </div>
    </div>
     

    </div>
	
        </section>
      <section id="services">
        <div className="section-start"></div>
        <div className="sub-head">
          <h2>SERVICES WE OFFER</h2>
        
        </div>
        {/*<div className="sub-head2">
        <p>With our expertise in web designing, UX/UI designing, mobile app development, GitHub, test automation, and testing, we are committed to delivering 
              top-notch solutions that meet your business objectives and exceed customer expectations. Our team of dedicated professionals works closely with you to
               understand your requirements, provide tailored recommendations, and deliver exceptional results.</p>
    </div>*/}
        <div className="">
          <div className="services-list">
            <div>
              <i className="fas fa-code"></i>
              <h2>Web Design</h2>
              <p>Our expert team of web designers specializes in creating visually stunning and highly functional 
                websites that leave a lasting impression on your target audience. We combine our creative flair
                 with a deep understanding of user experience to design websites that are not only aesthetically 
                 appealing but also intuitive and user-friendly. By leveraging the latest design trends, responsive layouts, 
                 and interactive elements, we ensure that your website not only looks great but also provides a seamless
                  browsing experience across different devices and platforms.</p>
  
            </div>
            <div>
              <i className="fas fa-crop-alt"></i>
              <h2>UI/UX Design</h2>
              <p>User Experience (UX) and User Interface (UI) are critical aspects of any successful digital product. 
                Our UX/UI designers are adept at crafting interfaces that prioritize user needs, enhance usability, 
                and create engaging experiences. We conduct in-depth user research and analysis to understand your target
                 audience and design interfaces that are intuitive, visually appealing, and optimized for conversions.
                  By combining usability principles, information architecture, and visual design, we create interfaces that captivate users and drive meaningful interactions.</p>
             
            </div>
            <div>
              <i className="fas fa-app-store"></i>
              <h2>App Design</h2>
              <p>In today's mobile-driven world, having a robust and user-friendly mobile app is essential for reaching a wider audience 
                and enhancing customer engagement. Our mobile app development team excels in building high-quality, native and cross-platform 
                apps for iOS and Android platforms. We follow industry best practices and leverage the latest technologies to create apps that 
                offer seamless performance, intuitive navigation, and rich features. From conceptualization to deployment and ongoing maintenance, 
                we provide end-to-end mobile app development services to transform your ideas into reality.</p>
   
            </div>
            <div>
              <i className="fas fa-app-store"></i>
              <h2>GitHub</h2>
              <p>GitHub is a powerful collaboration and version control platform that enables efficient code management and project collaboration. 
                Our team has extensive experience in utilizing GitHub for code repositories, version control, and collaboration among team members.
                 We can help you set up and optimize your GitHub workflows, ensure proper code documentation, facilitate seamless collaboration,
                  and implement best practices for code reviews and continuous integration.</p>
             
            </div>
            <div>
              <i className="fas fa-app-store"></i>
              <h2>Test Automation</h2>
              <p>To ensure the quality and reliability of your software applications, test automation is a crucial component of the development process. 
                Our consultants specialize in test automation frameworks and tools that allow for efficient and effective testing of your software.
                 We help you implement robust test automation strategies, design and develop automated test scripts, and integrate them into your CI/CD pipelines. 
                 By automating repetitive test scenarios, we accelerate the testing process, improve test coverage, and reduce time-to-market while maintaining the highest level of software quality.</p>
             
            </div>
            <div>
              <i className="fas fa-app-store"></i>
              <h2>Testing</h2>
              <p>Comprehensive testing is essential to deliver flawless software applications. Our team of skilled testers provides end-to-end testing services, 
                including functional testing, performance testing, security testing, and usability testing. We leverage a combination of manual and automated testing 
                techniques to ensure that your applications meet the highest standards of quality, functionality, and user experience. By meticulously testing your software 
                throughout the development lifecycle, we help you identify and resolve issues early, minimizing risks and ensuring a smooth user experience.</p>
              
            
            </div>
            
          </div>
       
        </div>
        <div class="text-center">
                <h4>WE WORK WITH</h4><br></br>
                <img  className="work-img" src={require("./images/Screenshot 2023-06-21 at 17.07.24.png")}></img>
                 
        </div>
        <div class = "sis-content">
        
         <p> SIS is well prepared to solve the most challenging retail and manufacturing problems of today. They provide system solutions to corporations worldwide, from Fortune 500 companies to small start-ups. </p>
          {!showMore ? (
        <a href="#" onClick={toggleMoreInfo}>Learn More</a>
      ) : (
        <>
        <div class="text-center">
                <h4>TEAM SIS</h4>
        </div>
          <div className="button-container">
      <div className={`scroll-container`}>
        <BubbleButton
          title="Jake Mays"
          description="CFO"
          // popupImage="m1-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="David Leslie"
          description="QA Manager"
          // popupImage="m2-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
        <BubbleButton
          title="Michael Schneider"
          description="QA Manager"
          // popupImage="m3-min.png"
          isPaused={isPaused}
          onClose={handlePopupClose}
          onClick={handleBubbleButtonClick}
        />
   
      </div>
    </div>
    <p>They have established long-term relationships with thier clients built on their ability to help 
      them in the full project lifecycle, from vision through implementation.</p>
        
          <a href="#" onClick={toggleMoreInfo}>Show Less</a>
        </>
      )}
      </div>
      </section>
      <section id="careers">
        <div className="section-start"></div>
        <div className="sub-head">
          <h2>CAREERS</h2>
        </div>
        <div className="about-content">
        <p>Are you looking for a dynamic and rewarding career in the world of consulting? Look no further! At RK consultancies, we are always seeking talented individuals who are passionate about making a difference.

As a member of our team, you'll have the opportunity to work with diverse clients from various industries, tackling complex challenges and driving meaningful change. We foster a collaborative and inclusive work environment that values innovation, integrity, and continuous learning.</p>
<p>If you are passionate about consulting, thrive in a fast-paced environment, and are ready to take on new challenges, we want to hear from you! Join our team and be part of a company that values your expertise and empowers you to make a difference.

To apply, please send your resume and cover letter to our email address. We look forward to reviewing your application and getting to know you better.</p>
</div>

 </section>

     
      <section id="contact">
      <div className="section-start"></div>
        <div className="sub-head">
      <h2>CONTACT US</h2>
      <p className="contact-para">Contact us anytime we will reach you in no time </p>
      </div>
  <div class="container">
  
      <div class="content"> 
      {/*<div class="left-side">
        <div class="address details">
          <i class="fas fa-map-marker-alt"></i>
          <div class="topic">Address</div>
          <div class="text-one">Manikonda, Hyderabad</div>
          <div class="text-two">Telangana</div>
        </div>
        <div class="phone details">
          <i class="fas fa-phone-alt"></i>
          <div class="topic">Phone</div>
          <div class="text-one">+91 9100884545</div>
        
        </div>
        <div class="email details">
          <i class="fas fa-envelope"></i>
          <div class="topic">Email</div>
          <div class="text-one">RK.IT.CONSULTINGS@GMAIL.COM</div>
          
        </div>
      </div> */}
      <div class="right-side">
        <div class="topic-text">Send us a message</div>
        <form  ref={form} onSubmit={handleSubmit}>
        <div class="input-box">
          <input type="text" placeholder="Enter your name" 
          name="name"
          value={name}
          
          onChange={handleInputChange}
          required="required"></input>
        </div>
        <div class="input-box">
          <input type="text" placeholder="Enter your email" 
          id='email'
          name="email"
          ref={email2}
          value={email}
          onChange={handleInputChange}
          required="required"></input>
        </div>
        <div class="input-box message-box">
        <textarea  id="message" 
        placeholder="Your Message" 
        name="message"
        ref={message2}
        value={message}
        onChange={handleInputChange}
        required="required" data-validation-required-message="Please enter a message."></textarea>
										
        </div>
        <div ><button className="button" type="submit">Send Now
            </button>
      
        </div>
      </form>
    </div>
    </div>
   </div> 
   <div className='extra-space'></div>
      </section></div>
      <section id="footer">
      <footer className="footer">
  <div className="footer-contact">
    <p>
      <span className="icon"><FaAddressCard /> ADDRESS: </span>
      <span className="icon-text">HYDERABAD, TELANGANA</span>
      <br />
      <span className="icon"><FaMailBulk /> MAIL: </span>
      <span className="icon-text"><a href="mailto:rk.it.consultings@gmail.com">RK.IT.CONSULTINGS@GMAIL.COM</a></span>
      <br />
      <span className="icon"><FaPhoneAlt /> PHONE: </span>
      <span className="icon-text"><a href='tel:+919100884545'>+91 9100884545</a></span>
    </p>
  </div>
  <p className="footer-copyright">
    © 2023 RK Consulting. All rights reserved.
  </p>
</footer>

</section>
    
    
    </body>
);
}

export default App;
