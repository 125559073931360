import React, { useState, useRef, useEffect } from 'react';

function BubbleButton({ title, description, popupImage, onClick, onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const popupRef = useRef(null);

  const handleClick = () => {
    setShowPopup(true);
    onClick();
    document.body.classList.add('popup-open');
  };

  const handleClose = () => {
    setShowPopup(false);
    onClose();
    document.body.classList.remove('popup-open');
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getImagePath = (path) => {
    return require(`./images/${path}`);
  };

  return (
    <>
      <div className="bubble" onClick={handleClick}>
        {title}
      </div>
      {showPopup && (
        <>
          <div className="overlay"></div>
          <div className={`popup ${showPopup ? 'open' : ''}`} ref={popupRef}>
            <div className="popup-content">
              <div className="popup-text">
                <h3>{title}</h3>
                <h5>{description}</h5>
              </div>
              {popupImage && (
                <>
                  {!imageLoaded && <div className="loading"></div>}
                  <img
                    className={`popup-img ${imageLoaded ? 'loaded' : ''}`}
                    src={getImagePath(popupImage)}
                    alt="Popup Image"
                    onLoad={handleImageLoad}
                  />
                </>
              )}
            </div>
            <button onClick={handleClose}>x</button>
          </div>
        </>
      )}
    </>
  );
}

export default BubbleButton;
